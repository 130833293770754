export const component = () => {
  const $videos = $(".youtube-video__movie");

  var tag = document.createElement("script");
  tag.src = "https://www.youtube.com/iframe_api";

  var firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  window.onYouTubeIframeAPIReady = function () {
    $videos.each(function () {
      var video = new Video($(this));
    });
  };

  //Probably doesn't need to be a class but a nice enbough chance to instantiate
  class Video {
    constructor($el) {
      this.$element = $el;
      this.$iframe = this.$element.find(".youtube-video__iframe");
      this.$btn = this.$element.find(".play");

      this.setupPlayer();
      this.setupEvents();
    }

    setupPlayer() {
      this.player = new YT.Player(this.$iframe[0]);
    }

    setupEvents() {
      this.$btn.click(() => {
        this.$element.css("background-image", "");
        this.$btn.fadeOut();
        this.$iframe.show();
        this.player.playVideo();
      });
    }
  }

  // NOTE: need to handle the double tap issue on iOS.
  // Otherwise the first tap will trigger the CSS but not navigate the users to the correct link.
  // Reference article: https://css-tricks.com/annoying-mobile-double-tap-link-issue/
  // Fix article: http://cssmenumaker.com/blog/solving-the-double-tap-issue-on-ios-devices/
  $(".youtube-video .youtube-video__cta").on("touchend", function (e) {
    var $element = $(this);
    if ($element) {
      window.location = $element.attr("href");
    }
  });
};
